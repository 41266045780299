@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: "Louis-Regular", "Arial","sans-serif";
$boldFontFamily: "Louis-Regular", "Arial","sans-serif";
$boldFontWeight: 400;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #4d4d4d;
$linkColor: #000000;
$labelColor: #aaaaaa;
$formColor: #333333;
$utilColor: #000000;
$priceColor: #000000;
$lightColor: #ffffff;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #4d4d4d;
$titleSecondaryColor: #ffffff;
$subtitleColor: #4d4d4d;
$subtitleSecondaryColor: #ffffff;
$successColor: #4d4d4d;
$warningColor: #f2bf24;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #808080;
$colorBtnAlpha: #ffffff;
$bgBtnBeta: #111111;
$colorBtnBeta: #ffffff;
$colorBtnOutlineAlpha: #111111;
$colorBtnOutlineBeta: #111111;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #111111;
$calculatorTabsTextColor: #ffffff;
$calculatorCloseTabs: #4d4d4d;
$calculatorCloseTabsColor: #686869;
$calculatorMainColor: #ffffff;
$calculatorButton: #e7302a;

$garageBackground: #ffffff;
$garageTextColor: #333333;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #4d4d4d;
$colorTopbarLogin: #b3b3b3;

$progressBarTitleBG: #808080;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #111111;
$progressBarTitleCloseColor: #ffffff;
$progressBarCloseButtonBG: #111111 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #bbbbbb;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #000000;
$paymentCalculatorTermWrapperBG: #BBBBBB;
$paymentCalculatorTermWrapperColor: #808080;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #4d4d4d;
$paymentCalculatorTermColor: #aaaaaa;

// ========
// Styles
// ========
@include breakpoint(mobile){
    .widget-sr{
        &.makes__gmc,
        &.makes__chevrolet,
        &.makes__buick,
        &.makes__cadillac{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
            .payment-calculator-wrapper{
                z-index: auto !important;
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;
    &.makes__gmc,
    &.makes__chevrolet,
    &.makes__buick,
    &.makes__cadillac{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .listing-used{
            background-color: $bgPrimaryColor;
        }
        .listing-tile-note, .listing-tile-note .vehicle-note{
            background: $bgPrimaryColor;
        }
        .listing-used .listing-used-inner-content .listing-used-header-refine button,
        .listing-used-payment .listing-used-set-budget button{
            color: $lightColor;
        }
        .widget-login .widget-login-desktop{
            padding: 0;
        }
        .purchase-methods-cash .purchase-detail-line.price_total{
            border: 0px;
            background-color: $paymentCalculatorTermWrapperBG;
        }    

        .calculatorColumn{
            border-right: 1px solid $paymentCalculatorTermWrapperBG;
        }
        .calculator-tradeIn, .new-customize-payment, .calculator-cash-down, .section-distance{
            border-bottom: 1px solid $paymentCalculatorTermWrapperBG;
        }

        .calculator-done{
            border-top: 1px solid $paymentCalculatorTermWrapperBG;
        }
        .calculatorColumn .purchase-detail-line .is-util,
        .calculatorColumn .calculator-tradeIn .sr-link {
            color: $calculatorMainColor;
        }
        .calculator-term.selected .taxesIncluded-section.is-light .payment-detail.is-light span{
            color: $priceColor;
        }
        .new-customize-payment .taxesIncluded-section.is-light span.is-util{
            color: $calculatorMainColor;
        }
        .style-color__button.selected{
            border: 3px solid $textColor;
        }

        .bloc-options__single--footer.selected .option-choose-cta {
            background-color: $textColor;
            border-color: $textColor;
        }

        .listing-used-bodystyle.selected.is-price:before {
            color: $utilColor;
        }

        .vehicleBanner-personsViewed{
            background: $paymentCalculatorTermWrapperColor;
            color: #ffffff;
            opacity: .8;
        }

        .vehicleBanner-photos .vehicleBanner-photo-button{
            background: $paymentCalculatorTermWrapperColor;
            opacity: .7;
        }

        .protection-single--content .protection-cta-selector.selected {
            background-color: $textColor;
            color: $textColor;
            border-color: $textColor;
        }
        .widget-garage .vehicle-wrapper-info .vehicle-transaction {
            color: $utilColor;
        }

        .widget-sr input[type=radio]:checked+label:after{
            background: $utilColor!important;
        }

        .widget-profile-popupElement form#profileForm [type=checkbox]:checked+label:before, .widget-profile-popupElement form#profileForm input[type=radio]:checked+label:before,
        .widget-sr input[type=radio]:checked+label:before{
            border-color: $utilColor!important;
        }

        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected {
            color: $utilColor;
        }
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button.selected{
            color: $utilColor;
            border-color: $utilColor;
        }
        .sr-modal__wrapper div.style-specs__modal-close-bloc{
            overflow: auto;
        }
        .TransactionProgressBar--wrapper{
            background-color: $colorTopbarLogin;
        }
        .TransactionProgressBar--progression{
            background-color: $textColor;
        }
        .HeaderMessage.message-handler.warning{
            background-color: $textColor;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            border-bottom-color: $colorTopbarLogin;
        }
        .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,$colorTopbarLogin 55%);
        }
        .car-number.is-light,
        .carfax-content .is-light,
        .carfax-logo .is-light,
        .certified-text.is-light{
            color: $textColor;
        }
        .listing-tile{
            .listing-tile-image .car-tagline{
                color: $lightColor;
            }
            .listing-tile-footer{
                width: 100%;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                padding: 20px;
                justify-content: flex-start;
                align-items: center;
                .listing-tile-origin{
                    width: 100%;
                    padding: 0;
                    margin: 10px 0 0;
                    justify-content: flex-start;
                    .icon-map{
                        font-size: 15px;
                    }
                    p{
                        font-size: 11px;
                    }
                    &.is-light{
                        color: $textColor;
                    }
                }
                .vehicle-certification{
                    padding: 0;
                    .carfax-icon{
                        margin-right: 0;
                    }
                    .certification-badge{
                        justify-content: flex-start;
                        -ms-flex-pack: start;
                        img{
                            max-width: 100%;
                        }
                        .certification-badge__image{
                            margin-bottom: 8px;
                        }
                        .certification-badge-secondary{
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .TradeIn__wrapper{
            .is-light{
                color: #4d4d4d;
            }
        }
        .confirm_tradein--icon,
        .confirm_financing--icon,
        .confirm_deposit--icon,
        .timeSavedBlock{
            border-color: #4d4d4d;
        }
        .confirm_tradein--icon icon,
        .confirm_financing--icon icon,
        .confirm_deposit--icon icon,
        .confirm_financing--title,
        .confirm_tradein--title,
        .confirm_deposit--title,
        .Appointment__content .appointment__steps--single .timeSaved .doneLabel icon,
        .Appointment__content .appointment__steps--single .timeSaved .doneLabel,
        .Appointment__content .appointment__steps--single.is-validated .title_wrapper>icon,
        .section-checkout-header__progressBar-stage.validated .picto-showroom:first-child
        {
            color: #4d4d4d;
        }
        .section-checkout-header__progressBar-stage.selected .picto-showroom:first-child{
            color: #ffffff;
        }
        .sr-calculator-tabs{
            li.current{
             border-color: #4d4d4d !important;
            }
        }
        .sr-paymentCalculator-dropdown{
            .Select-control{
             background-color: #303030;
            }
        }
        .sr-garage,
        .checkout__content-right-section{
            .is-light{
                color: #000000;
            }
        }
        .listing-tile-all-price .disclaimer-price.is-light,
        .payment-summary-section .listing-tile-all-price .disclaimer-price.is-light{
            color: $textColor !important;
        }
        .listing-new-tile .listing-new-tile-spec .listing-tile-specification-specifics .listing-tile-specification-odometer,
        .listing-new-tile .listing-new-tile-spec .listing-tile-specification-specifics .listing-tile-specification-stock,
        .listing-new-tile .listing-tile-vin, .vehicle-overview__vehicle-title .vehicle-stockno,
        .vehicle-overview__vehicle-title .vehicle-vinno{
            color: #4d4d4d;
        }
        .section-showroom-header{
            .sr-paymentCalculator-background{
                background-color: #707070 !important;
            }
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .listing-tile-footer{
            .listing-tile-origin{
                color: #000000 !important;
            }
        }

        .payment-calculator-wrapper{
            z-index: 9;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;
        
        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}